@import '../../config/globalStyleVars.scss';


$topBottomPadding: 1em;

.center-page {
    padding: $topBottomPadding $siteMarginMax;
}

@media (max-width: 600px) {
    .center-page {
        padding: $topBottomPadding $siteMarginSmall;
    }
}

@media (min-width: 601px) and (max-width: 1920px){
    .center-page {
        padding: $topBottomPadding $siteMargin;
    }
}