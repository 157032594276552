$darkGray: #272727;
$almostWhite: #f5f5f5;
$almostWhiteHighlight: rgba(238, 238, 238, 0.4);
$siteMargin: 10vw;
$siteMarginSmall: 4vw;
$siteMarginMax: 20vw;
$siteMaroon: rgb(85, 4, 4);
$siteMaroonSecondary: rgba(85, 4, 4, .4);
$headingTopMargin: 1rem;
$headingBottomMargin: 1.5rem;
$goldHighlight: #d9d981b3;
$iconTitleGoldTheme: red;
