.layout {
    display: flex;
    flex-direction: column;
    //background-color: red;
    min-height: 100vh;
    .pages {
        flex: 1;
    }


}
