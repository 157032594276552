@import '../../config/globalStyleVars.scss';

.contact-page {
    h1 {
        text-align: center;
        margin-top: $headingTopMargin;
        margin-bottom: $headingBottomMargin;
        --fa-secondary-color: yellow;//$iconTitleGoldTheme;
    }

    .contact-panel {
        margin-top: 1rem;

    }
    .contact-page__form {

        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-input-icon {
            color: $siteMaroon;
        }

        button {
            padding: .5em 10vw;
            background-color: $siteMaroon;
            color: $almostWhite;
            border-radius: 5px;
            border: none;
            align-self: center;
            outline: none;
            transition: all .3s ease;
            &:hover {
                filter: brightness(1.4);
                box-shadow: -1px 2px 8px 0 rgba(0, 0, 0, .7);
                transform: translateY(-3px);
            }
        }
        .contact-page__sender-info {
            display: flex;
            gap: 2rem;
            align-items: center;

            .contact-page__sender-info-left {

                h3 {
                    text-align: center;
                }
                svg {
                    color: $siteMaroon;
                }
                
            }

            .contact-page__sender-info-right {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: .5em;
                flex: 1;

                input {
                    padding: .4em .7em;
                    border-radius: 5px;
                    border: solid 1px #bbb;
                    transition: outline .15s ease;
                    
                    &:focus{
                        outline:$siteMaroonSecondary 2px solid;
                    }
                }
                
                
            }

        }

        .contact-page__message {
            display: flex;
            flex-direction: column;
            gap: .25rem;
            
            textarea {
                padding: .4em .7em;
                border-radius: 5px;
                border: solid 2px #bbb;
                transition: outline .15s ease;
                &:focus{
                    outline: $siteMaroonSecondary 2px solid;
                }
            }
        }

    }
}