.gw-animated-title {
    font-size: 2em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    display: flex;
    justify-content: center;
}

#my-first-name {
    margin-left: 0;
}

.line2 {
    margin-left: 1.25em;
    #dot-com {
        font-size: .5em;
    }
}

