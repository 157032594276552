@import '../../config/globalStyleVars.scss';

$topBottomPadding: .75rem;


.footer {
    background-color:$darkGray;
    color: $almostWhite;
    padding: $topBottomPadding $siteMarginMax;
    font-size: 1.25rem;
    text-align: right;
    box-shadow: inset 0 -2px 9px -2px #ffffffbb;


    .initials-svg {
        fill: $almostWhite;
        height: 1.5em;
    }
}

@media (max-width: 600px) {
    .footer {
        padding: $topBottomPadding $siteMarginSmall;
    }
}

@media (min-width: 601px) and (max-width: 1920px){
    .footer {
        padding: $topBottomPadding $siteMargin;
    }
}