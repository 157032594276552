@import '../../../config/globalStyleVars.scss';

.happy-logo {
    height: 3em;
    fill: $almostWhite;
    //border: solid red 3px;
    //text-align: left;
}

@media (max-width: 800px) {
    .happy-logo {
        display: none;
    }
}