@import '../../config/globalStyleVars.scss';

$topBottomPadding: 1em;

.nav-bar {
    //height: 2rem;
    background-color: $darkGray;
    display: flex;
    padding: $topBottomPadding $siteMarginMax;
    //justify-content: space-between;
    align-items: center;
    color: $almostWhite;
    gap: 1rem;
    box-shadow: inset 0 3px 12px -4px #ffffffbb;

    &>* {
        flex: 1;
    }

}



@media (max-width: 600px){
    .nav-bar{
        flex-direction: column;
        padding: $topBottomPadding $siteMarginSmall;
    }
}

@media (min-width: 601px) and (max-width: 1920px){
    .nav-bar {
        padding: $topBottomPadding $siteMargin;
    }
}