@import '../../config/globalStyleVars.scss';

.home-page {
    display: flex; 
    flex-direction: column;
    gap: 1rem;
}

.home-page__message {
    max-width: 800px;
    font-weight: 600;
    margin-top: 1rem;
    
    p {
        padding: .25rem 0;
        a {
            text-decoration: none;
            color: rgb(85, 4, 4);
            //font-weight: 700;
        }
    }
    ul {
        margin-top: 1em;
        list-style: none;
        li {
            padding: 0.4em;
            margin-left:-0.4em;
            a {
                margin-left:-0.4em;
                padding: 0.1em 0.4em;
                text-decoration: none;
                border-radius: 5px;
                color: $siteMaroon;
                &:hover {
                    background-color: $goldHighlight;
                }
                line-height: 0;
            }
        }

    }
    img {
        width: 4em;
        border-radius: 1.25em;
        border-left: 2px solid #aaa;
        border-top: 2px solid #aaa;
        margin-right: 1rem;
        background-color: #333;
    }

}

.home-page__title {
    text-align: center;
    margin-top: $headingTopMargin;
    margin-bottom: $headingBottomMargin;
    --fa-primary-color: #d9d981;
    --fa-secondary-opacity: .75;

}